.App {
    text-align: center;
    background-color: #282c34;
    height: 100vh;
}

.App-logo {
    height: 30vmin;
    pointer-events: none;
    margin-bottom: 60px;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    padding-top: 10vmin;
    margin-bottom: 20px;
}

p {
    color: white;
}

.App-link {
    color: #61dafb;
}
.login-btn {
    background-color: #282c34;
    border: none;
    cursor: pointer;
}

.login-btn > img {
    position: relative;
}

.login-btn > img:hover {
    opacity: 0.8;
}

.login-btn > img:active {
    opacity: 1;
    border: 1px #61dafb;
    left: 2px;
    top: 2px;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
